import { baseUrl, stripeUrl, hmacKey, stripeSecretKey,stripeSecretKeyforCAN, stripeSecretKeyforUSA } from "../env.json";
import axios from "axios";
import { getLatitude, getLongitude, getToken } from "../common";

var CryptoJS = require("crypto-js");

const hmacConvert = (method, data) => {
    var string = `${hmacKey}-${method}-${data !== "" ? JSON.stringify(data) : ""}`;
    var hash = CryptoJS.HmacSHA256(string, hmacKey);
    var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    return hashInBase64;
};

const decode = (txt) => {
    let result = atob(txt);
    return result;
};

export const getMethod = async (url) => {
    let hKey = await hmacConvert("GET", "");
    let token = getToken();

    try {
        let data = await axios({
            method: "GET",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getMethodWithPlatfrom = async (url, platformType) => {
    let hKey = await hmacConvert("GET", "");
    let token = getToken();

    try {
        let data = await axios({
            method: "GET",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                platform: platformType,
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getMethodWithoutBaseUrl = async (url) => {
    let hKey = await hmacConvert("GET", "");
    let token = getToken();

    try {
        let data = await axios({
            method: "GET",
            url: url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethod = async (url, body) => {
    let hKey = await hmacConvert("POST", body);

    try {
        let data = await axios({
            method: "POST",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethodWithPlatformWithoutToken = async (url, body, platforms) => {
    let hKey = await hmacConvert("POST", body);

    try {
        let data = await axios({
            method: "POST",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                lat: getLatitude(),
                long: getLongitude(),
                platform: platforms,
                "Signing-key": hKey,
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethodWithPlatform = async (url, body, platform) => {
    let hKey = await hmacConvert("POST", body);
    let token = getToken();

    try {
        let data = await axios({
            method: "POST",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                platform: platform,
                "Signing-key": hKey,
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const putMethod = async (url, body) => {
    let hKey = await hmacConvert("PUT", body);

    try {
        let data = await axios({
            method: "PUT",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethodWithToken = async (url, body) => {
    let hKey = await hmacConvert("POST", body);
    let token = getToken();

    try {
        let data = await axios({
            method: "POST",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getPostMethodWithToken = async (url,platform) => {
    let hKey = await hmacConvert("GET", "");
    let token = getToken();

    try {
        let data = await axios({
            method: "GET",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                platform: platform,
                "Signing-key": hKey,
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
};
export const putMethodWithToken = async (url, body) => {
    let hKey = await hmacConvert("PUT", body);
    let token = getToken();

    try {
        let data = await axios({
            method: "PUT",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethodWithTokenProgress = async (url, body, progress) => {
    let hKey = await hmacConvert("POST", body);
    let token = getToken();

    try {
        let data = await axios({
            method: "POST",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
            data: body,
            onUploadProgress: progress,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const deleteMethodWithToken = async (url) => {
    let hKey = await hmacConvert("DELETE", "");
    let token = getToken();

    try {
        let data = await axios({
            method: "DELETE",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
            // data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const deleteMethodWithTokenAndData = async (url, body) => {
    let hKey = await hmacConvert("DELETE", body);
    let token = getToken();

    try {
        let data = await axios({
            method: "DELETE",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token " + token,
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getProductDetails = async (storeId, productId, moduleName) => {
    let hKey = await hmacConvert("GET", "");

    try {
        let data = await axios({
            method: "GET",
            url: baseUrl + "vendor/store/" + storeId + "/product/" + productId + "/?module=" +moduleName,
            headers: {
                accept: "application/json",
                Authorization: "token " + getToken(),
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getFinancialTabDetails = async (storeId, financialId) => {
    let hKey = await hmacConvert("GET", "");

    try {
        let data = await axios({
            method: "GET",
            url: baseUrl + "vendor/store/" + storeId + "/financial/" + financialId,
            headers: {
                accept: "application/json",
                Authorization: "token " + getToken(),
                lat: getLatitude(),
                long: getLongitude(),
                "Signing-key": hKey,
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getMethodLocal = async (url) => {
    let hKey = await hmacConvert("GET", "");

    try {
        let data = await axios({
            method: "GET",
            url: baseUrl + url,
            headers:{
                "Signing-key": hKey,
            }
            // headers: { accept: "application/json", Authorization: "token " + token },
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethodWithTokenLocal = async (url, body) => {
    let hKey = await hmacConvert("POST", body);

    try {
        let data = await axios({
            method: "POST",
            url: baseUrl + url,
            headers:{
                "Signing-key": hKey,
            },
            // headers: { accept: "application/json", Authorization: "token " + token },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const putMethodWithTokenLocal = async (url, body) => {
    let hKey = await hmacConvert("PUT", body);

    try {
        let data = await axios({
            method: "PUT",
            url: baseUrl + url,
            headers:{
                "Signing-key": hKey,
            },
            // headers: { accept: "application/json", Authorization: "token " + token },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getCardDetails = async (customerId, cardId) => {
    let hKey = await hmacConvert("GET", "");

    try {
        let data = await axios({
            method: "GET",
            url: "https://api.stripe.com/v1/accounts/" + customerId + "/external_accounts/" +cardId,
            headers: {
                accept: "application/json",
                "Signing-key": hKey,
                Authorization: "Bearer sk_test_51DZCjXBQlwNcwoAXPiBgz9Kn0MGxzZsx6MTXaL2STQz4MjssHuBHnLFoYqN9YVPodSoRH5mv8oi2erM1KxI75Slt00vccQxjvL",
                lat: getLatitude(),
                long: getLongitude(),
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethodWithStripe = async (url, body) => {
    try {
        let data = await axios({
            method: "POST",
            url: stripeUrl + url,
            headers: { 
                'content-type': "application/x-www-form-urlencoded", 
                Authorization: "Bearer "+stripeSecretKey
            },
            data: body,
        });
        
        return data;
    } catch (error) {
        return error.response;
    }
};

export const deleteMethodWithStripe = async (url, body) => {
    try {
        let data = await axios({
            method: "DELETE",
            url: stripeUrl + url,
            headers: { 
                'content-type': "application/x-www-form-urlencoded", 
                Authorization: "Bearer "+stripeSecretKey
            },
            data: body,
        });
        
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getMethodWithStripe = async (url, country) => {
    let token;
    console.log('GET '+country);
    if(country === 'USA') {
        token = stripeSecretKeyforUSA;
    } else {
        token = stripeSecretKeyforCAN
    }

    try {
        let data = await axios({
            method: "GET",
            url: stripeUrl + url,
            headers: { 
                Authorization: "Bearer "+token
            }
        });

        return data;
    } catch (error) {
        return error.response;
    }
};
