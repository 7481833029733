import React, { useState, useEffect } from "react";
import "./login.scss";
import loginImage from "../../assets/images/loginImage.png";
import { useHistory } from "react-router-dom";
import Button from "../../components/button/button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { getMethod, postMethod, postMethodWithPlatformWithoutToken } from "../../utils/services/api";
import urls from "../../utils/services/apiUrl.json";
import { setStoreId, setUserSession, setUserRole, getUserRole,setUserOnBoarding } from "../../utils/common";
import LoadingOverlay from "react-loading-overlay";
import Eye from "../../assets/images/eye.png";
import HideEye from "../../assets/images/hideEye.png";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OtpInput from 'react-otp-input';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

function Login() {

    let steps = "";
    const [state, setState] = useState({ username: "", password: "" });
    const [signUpForm, setSignUpForm] = useState(false);
    const [loader, setLoader] = useState(false);
    const [onBoarding, setOnBoarding] = useState(false);
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [loginScreenVisible, setLoginScreenVisible] = useState(true);
    const [otpValue, setOtpValue] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [countInvalid, setCountInvalid] = useState(0);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    const onBoardingStatus = async () => {
        await getMethod(urls.verifyOnboardStatus).then((response) => {
            if (response.status === 200) {
                setOnBoarding(response.data.status);
                setUserOnBoarding(response.data.status);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleSubmit = (event) => {
        setOtpValue("");
        if (state.username !== "" && state.password !== "") {
            setLoader(true);
            let body = {
                username: state.username.toLowerCase(),
                password: btoa(state.password),
                role: btoa('super-admin')
            };

            postMethodWithPlatformWithoutToken(urls.login, body, 'super-admin').then((response) => {
                if (response.status === 200) {
                    setLoader(false);
                    toast.success(response.data.response)
                    setLoginScreenVisible(false)
                } else {
                    setLoader(false);
                    toast.error(response.data.error);
                }
            }).catch((error) => {
                setLoader(false);
            });
        }
    };

    const verifiedOtp = async () => {
        let body = {
            username: state.username.toLowerCase(),
            otp: otpValue
        }

        postMethod(urls.verifyLoginOtp, body).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.success)
                setStoreId(response.data.store_id);
                setUserRole(response.data.role_type);
                setUserSession(response.data.token);
                onBoardingStatus();
                window.$store_name = response.data.store_name;

                if (response.data.role_type === "super-admin") {
                    history.push("/onlineenquiry")
                } else if (onBoarding === true && steps === 5) {
                    history.push("/home")
                } else {
                    getSteps()
                }
            } else {
                toast.error(response.data.error)

                let countNumber = countInvalid + 1;
                setCountInvalid(countNumber);

                if (countNumber >= 3) {
                    setLoginScreenVisible(true)
                    sessionStorage.clear();
                    setOtpValue('');
                }
            }
        }).catch((error) => {
            setLoader(false);
        });
    }

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                steps = response.data.signup_step;

                if (steps === 1) {
                    history.push("/addProduct")
                } else if (steps === 2) {
                    history.push("/financials")
                } else if (steps === 3) {
                    history.push("/plans")
                } else if (steps === 4) {
                    history.push("/review")
                } else if (steps === 5) {
                    history.push("/home")
                } else {
                    history.push("/aboutStore");
                }
            }
        });
    };

    const handleOtpChange = (e) => {
        setOtpValue(e)
    }

    const createstore = async () => {
        history.push("/createstore");
    };

    const gotoBack = async () => {
        history.push("/");
    };

    const gotoForgotPassword = async () => {
        if (state.username !== "") {
            let body = {
                email: state.username,
            };
            await postMethodWithPlatformWithoutToken(urls.linkForForgetPassword, body, 'super-admin').then((response) => {
                if (response.status === 200) {
                    toast.success("Link has been sent to your email to set password");
                } else {
                    toast.error(response.data.error);
                }
            });
        } else {
            toast.error("Please enter username");
        }
    };

    return (
        <LoadingOverlay active={loader} spinner text="Processing...">
            <div className="container-fluid p-0 m-0">
                <div className="outerDiv row p-0 m-0">
                    <div className="loginBusinessDeal col-6 p-0 m-0">
                        <div className="back_login" onClick={() => gotoBack()}>
                            <ArrowBackIcon style={{ fontSize: "3em" }} />
                        </div>
                        <img className="shopezImage" src={loginImage} />
                        <p className="loginPlaceholder">
                            Lorem ipsum dolor sit amet, consectetur
                            <br /> adipiscing elit. Morbi amet, volutpat
                            <br /> habitasse netus.
                        </p>
                    </div>

                    {loginScreenVisible ?
                        <div className="loginForm col-6 p-0 ">
                            <div className="container">
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <div className="heading-title text-center mb-3">Login</div>
                                        {/*<div className="h6-label text-center" style={{ color: "#808080" }}>
                                            Don't have an account?&nbsp;
                                            <span style={{ color: "#4472C4" }} onClick={() => createstore()}>
                                                Create your Store
                                            </span>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12">
                                    <div className="h6-label text-center" style={{ color: "#808080" }}>
                                        Please enter your credentials to proceed further
                                    </div>
                                </div>
                            </div>

                            <ValidatorForm>
                                <div className="ml-5 mr-5 pl-xl-5 pr-xl-5">
                                    <div className="row mt-4">
                                        <div className="col-10 offset-1">
                                            <TextValidator
                                                errorMessages={"Please enter a valid email"}
                                                validators={[
                                                    "isEmail",
                                                    "required",
                                                ]}
                                                onChange={handleEmailChange}
                                                value={state.username}
                                                name="username"
                                                label="Username"
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-10 offset-1">
                                            <TextValidator
                                                required
                                                errorMessages={"Please enter the password"}
                                                validators={["required"]}
                                                onChange={handleChange}
                                                inputProps={{ maxLength: 20 }}
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                label="Password"
                                                variant="outlined"
                                                value={state.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton>
                                                                {showPassword ?
                                                                    <VisibilityIcon onClick={() => setShowPassword(!showPassword)} />
                                                                    :
                                                                    <VisibilityOffIcon onClick={() => setShowPassword(!showPassword)} />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-10 offset-1">
                                            <div style={{ color: "#4472C4", textAlign: "end", cursor: "pointer" }} className="h6-label" onClick={() => gotoForgotPassword()}>
                                                Forgot password?
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-10 offset-1">
                                            <Button
                                                validatorListener={(value) => console.log("value")}
                                                onClick={handleSubmit}
                                                className="btn btn-primary w-100 text-truncate"
                                                name="Log In"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                        :
                        <div className="loginForm col-6 p-0 login_otp_card_container">
                            <div className="container">
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <div className="heading-title text-center mb-3">OTP verification</div>
                                        <div className="h6-label text-center" style={{ color: "#808080" }}>
                                            {state && state.username}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12">
                                    <div className="h6-label text-center" style={{ color: "#808080" }}>
                                        Please enter one time verification code.
                                    </div>
                                </div>
                            </div>

                            <div>
                                <ValidatorForm>
                                    <div className="row mt-3">
                                        <div className="col-8 offset-2 d-flex justify-content-center">
                                            <div className="forgot_password mt-3 otp-input">
                                                <OtpInput
                                                    value={otpValue}
                                                    onChange={(e) => handleOtpChange(e)}
                                                    numInputs={6}
                                                />

                                                <div style={{ color: "#4472C4", textAlign: "end", cursor: "pointer",marginTop:"10px" }} className="h6-label" onClick={() => handleSubmit()}>
                                                    Resend OTP?
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="muiResendOtp" onClick={() => resendOtp()}>
                                        Resend OTP?
                                    </div>*/}

                                    <div id="recaptcha-container"></div>
                                    <div className="row mt-3">
                                        <div className="col-8 offset-2">
                                            <div className="d-flex justify-content-center mt-5">
                                                <Button id="sign-in-button" onClick={() => verifiedOtp()} className="btn btn-primary btn-lg text-truncate mb-5" name="Verify"></Button>
                                            </div>
                                        </div>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </LoadingOverlay>
    );
}
export default Login;
